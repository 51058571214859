/* Define wave animation for Y-axis movement */
@keyframes waveY {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Define color cycle animation */
  @keyframes colorCycle {
    0%, 100% {
      color: #ef6f40;
    }
    25% {
      color: #c63713;
    }
    50% {
      color: #c63713;
    }
    75% {
      color: #ef6f40;
    }
  }
  
  /* Apply both waveY and colorCycle animations */
  .animate-wave {
    display: inline-block;
    animation: waveY 3s infinite, colorCycle 2s infinite;
  }
  
  /* Define wave animation for X-axis movement */
  @keyframes waveX {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
  }
  
  /* Apply waveX animation for border container */
  .border-container {
    animation: waveX 2s infinite alternate;
    margin: 0 auto;
  }
  